import { DateTime } from "luxon";

export interface TaxedAmount {
    net: number;
    taxRate: number;
    tax: number;
    gross: number;
}

export enum CalculationCase {
    PerUsage = 'PerUsage',
    PerHour = 'PerHour',
    PerDay = 'PerDay'
}

export interface Tenant {
    centerId: string;
    name: string;
    email: string|null;
    phone: string|null;
    cubes: string[];

    maxRentPeriod: number|null;
    maxRentals: number|null;
    emailDomains: string[];
    signUpDomains: string[];

    requireManuallyApproval: boolean;
    enabledCostCenter: boolean;
    rentalEndsAfterReturn: boolean;
    receiveEmails: boolean;

    whitelist: string[];

    enabledTicketOnCube: boolean;

    sendSmsOnRental: boolean;
    smsCountryCode:string;
    smsTemplate: string;

    overdueReminderHours: number;

    rentalInfo: {
        de: string;
        en: string;
    };

    reducedMenuOptions: boolean;
    displayBorrowerData: boolean;

	requiredEmailVerificationForSignUp: boolean;
	requiredPhoneForSignUp: boolean;
	requiredPhoneVerificationForSignUp: boolean;
	requiredAddressForSignUp: boolean;
	requiredNameForSignUp: boolean;
}

export interface ChangeTenantRequest {
    maxRentPeriod: number|null;
    maxRentals: number|null;
    emailDomains: string[];
    signUpDomains: string[];
    requireManuallyApproval: boolean;
    enabledCostCenter: boolean;
    rentalEndsAfterReturn: boolean;
    receiveEmails: boolean;
    whitelist: string[];
    enabledTicketOnCube: boolean;
    sendSmsOnRental: boolean;
    smsCountryCode: string;
    smsTemplate: string;
    overdueReminderHours: number;
    rentalInfo: {
        de: string;
        en: string;
    };
    reducedMenuOptions: boolean;
    displayBorrowerData: boolean;
	requiredEmailVerificationForSignUp: boolean;
	requiredPhoneForSignUp: boolean;
	requiredPhoneVerificationForSignUp: boolean;
	requiredAddressForSignUp: boolean;
	requiredNameForSignUp: boolean;
	email?: string;
	phone?: string;
}

export interface TenantSignUpInformation {
    tenantId: string;
    emailDomains: string[];
    signUpDomains: string[];
    enabled: boolean;
}

export interface CostCenter {
    uuid: string;
    name: string;
    description?: string;
    managers?: User[];
}

export interface CostCenterDetails {
    name: string;
    description: string|null;
    userUuids: string[];
}

export interface User {
    uuid: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    accessCode: string;

    role: UserRole;

    receiveEmails: boolean;

    root: boolean;
    active: boolean;

    costCenters: CostCenter[];

    locale: UserLocale;

    whitelists: Category[];
    blacklists: Category[];

    inspector: boolean;

    accessKey: string|null;
	nfcToken: string|null;

    existed: boolean;
}

export interface CreateUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    locale: UserLocale;
    costCenterUuids: string[];
	nfcToken: string|null;
}

export interface UserDetails {
    firstName: string|null;
    lastName: string|null;
    role: UserRole;
    costCenterUuids: string[];
    whitelists: string[];
    blacklists: string[];
    locale: UserLocale;
    inspector: boolean;
    receiveEmails: boolean;
    accessKey: string|null
	nfcToken: string|null;
}

export interface ChangeUserPasswordRequest {
    password: string;
    confirmPassword: string;
}

export enum UserRole {
    TenantAdmin = 'TenantAdmin',
    TenantUser = 'TenantUser',
    Borrower = 'Borrower',
}

export enum UserLocale {
    English = 'en',
    German = 'de'
}

export interface Model {
    uuid: string;
    name: string;
    description?: string;
    imageUrl?: string;
    tenantId: string;
    items: Item[];
    maxRentPeriod?: number;
    amount: TaxedAmount|null;
    calculationCase: CalculationCase|null;
    category?: Category;
}

export interface ModelDetails {
    name: string;
    description?: string;
    imageUrl?: string;
    maxRentPeriod: number|null;
    amount: TaxedAmount|null;
    calculationCase: CalculationCase|null;
    categoryUuid?: string;
}

export interface StoredItem {
    cubeId: string;
    boxNumber: string;
}

export interface Item {
    uuid: string;
    foreignId?: string;
    name: string;
    description?: string;
    images: string[];
    stored?: StoredItem;
    model?: Omit<Model, 'items'>
    maxRentPeriod?: number;
    amount: TaxedAmount|null;
    calculationCase: CalculationCase|null;
    disabled: boolean;
    category?: Category;
    needInspection?: boolean;
    adminCode?: string;
    openCode?: string;
    accessCodeGenerated?: DateTime;
}

export interface ItemDetails {
    foreignId?: string;
    name: string;
    description: string;
    images: string[];
    modelUuid?: string;
    maxRentPeriod: number|null;
    amount: TaxedAmount|null;
    calculationCase: CalculationCase|null;
    disabled: boolean;
    needInspection: boolean;
    categoryUuid?: string;
}

export interface ItemSetStateRequest {
    disabled: boolean;
}

export interface ItemStoreRequest {
	itemUuid: string;
	cubeId: string;
	boxType?: string;
	boxNumber?: string;
}

export enum TicketState {
    Open = "Open",
    Closed = "Closed"
}

export enum TicketType {
    Defective = 'Defective',
    Lost = 'Lost',
    Maintenance = 'Maintenance',
    Misc = 'Misc'
}

export interface Ticket {
    uuid: string;
    item: Item;
    rental?: Rental;
    state: TicketState;
    type: TicketType;
    tenantId: string;
    comments: Comment[];
    createdAt: string;
}

export interface TicketCreateRequest {
    itemUuid: string;
    rentalUuid: string|null;
    comment: string;
    type: TicketType;
}

export interface TicketSetStateRequest {
    comment: string;
    state: TicketState;
}

export interface Comment {
    uuid: string;
    comment: string;
    user: User|null;
    createdAt: string;
}

export interface CommentCreateRequest {
    comment: string;
}

export enum RentalState {
    Created = "Created",

	RequireApproval = 'RequireApproval',
    BorrowerPickedUp = "BorrowerPickedUp",
    LenderPickedUp = "LenderPickedUp",
    LenderReturned = "LenderReturned",
    BorrowerReturned = "BorrowerReturned"
}

export interface RentalCallback {
    method: string;
    url: string;
}

export interface RentalServiceItem {
    name: string;
    description: string;
}

export interface RentalBorrower {
    name?: string;
    email?: string;
    phone?: string;
    rentalEndsAfterReturn?: boolean;
    rentalEndsAutomaticallyAfterReturn?: number;
    notifyOnCreation: boolean;
    accessCode?: string;
    pickedUp: DateTime;
    returned: DateTime;
    canAccess: boolean;
}

export interface RentalLender {
    accessCode?: string;
    pickedUp?: DateTime;
    returned?: DateTime;
    canAccess: boolean;
}

export enum RentalLogType {
    Created = "Created",
    Reminder = "Reminder",
    BorrowerPickedUp = "BorrowerPickedUp",
    BorrowerReturned = "BorrowerReturned",
    BoxOpened = "BoxOpened",
    BoxClosed = "BoxClosed",
    BoxBlocked = "BoxBlocked",
    BoxBreakin = "BoxBreakin"
}

export interface RentalLog {
    type: RentalLogType;
    createdAt: DateTime;
}

export interface Rental {
    uuid: string;
    foreignId?: string;
    state: RentalState;
    callback?: RentalCallback;
    itemUuid: string;
    itemName: string;
    serviceItem?: RentalServiceItem;
    from: DateTime;
    until: DateTime;
    borrower: RentalBorrower;
    costCenter?: CostCenter;
    lender: RentalLender;
    created: DateTime;
    updated: DateTime;
    logs: RentalLog[];
    amount?: TaxedAmount;
    user?: User;
}

export interface RentalProlongResponse {
    prolonged: boolean;
    nextBorrower: string|null;
}

export enum RentalRequestStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export interface RentalRequest {
    uuid: string;
    foreignId?: string;
    item: Item;
    borrower: User;
    costCenter?: CostCenter;
    serviceItem?: RentalServiceItem;
    from: DateTime;
    until: DateTime;
    timezone: string;
    status: RentalRequestStatus;
    rejectReason?: string;
    rentalUuid?: string;
    created: DateTime;
    tenantId: string;
}



export interface RentalExportRequest {
    from: Date;
    until: Date;
    costCenterUuids: string[];
}

export interface RentalMappedEvent {
    id: number;
    title: string;
    allDay?: boolean;
    start: Date;
    end: Date;
    hexColor?: string;
    rental: Rental;
}

export interface RentalAvailabilityCheckRequest {
    itemUuid: string;
    from: DateTime;
    until: DateTime;
}

export interface UserItemAccessRequest {
    itemUuid: string;
    borrowerUuid: string;
}

export interface RentalAlternativeTimeframe {
    from: DateTime;
    until: DateTime;
}

export interface RentalAvailabilityCheckResponse {
    available: boolean;
    alternativeItem?: Item;
    alternativeTimeframes: RentalAlternativeTimeframe[];
    currentBorrower: string|null;
}

export interface CreateRentalBorrower {
    name: string|null;
    email: string|null;
    phone: string|null;
    accessKey: string|null;
	nfcToken: string|null;
    rentalEndsAfterReturn: boolean;
    rentalEndsAutomaticallyAfterReturn?: number;
    notifyOnCreation: boolean;
}

export interface CreateRentalServiceItem {
    name: string;
    description: string;
}

export interface CreateRentalRequest {
    itemUuid: string;
    foreignId: string,
    from: DateTime;
    until: DateTime;
    timezone: String;
    borrower: CreateRentalBorrower;
    serviceItem: CreateRentalServiceItem|null;
    borrowerUuid: string|null;
    costCenterUuid: string|null;
}

export interface Utilization {
    total: number;
    occupied: number;
    available: number;
    disabled: number;
}

export enum AppLockStatus {
    Open = "Open",
    Closed = "Closed",
    Breakin = "Breakin",
    Blocked = "Blocked",
}

export interface Cube {
    cubeId: string;
    description: string;
    connected: boolean;
    latitude: number;
    longitude: number;
    utilization: Utilization;
}

export interface CubeConfiguration {
	headless: boolean;
}

export interface Occupancy {
    uuid: string;
    cubeId: string;
    boxNumber: string;
    lock: string;
    accessCode: string;
    accessKeys: string[];
    created: DateTime;
    updated: DateTime;
    ended?: DateTime;
    itemUuid?: string;
    rentalUuid?: string;
}

export interface Box {
    number: string;
    description: string;
    lock: string;
    secondaryLock?: string;
    types: string[];
    lockStatus: AppLockStatus;
    secondaryLockStatus?: AppLockStatus;
    disabled: boolean;
    occupancies: Occupancy[];
}

export interface SetBoxStateRequest {
    disabled: boolean;
}

export interface Category {
    uuid: string;
    name: string;
    description?: string;
    parentCategory?: Category;
}

export interface CategoryTree extends Category {
    parentCategoryUuid?: string;
    children: CategoryTree[];
}

export interface CategoryDetails {
    uuid?: string,
    name: string,
    description?: string,
    parentCategoryUuid?: string,
}

export interface ImportRequest {
    csvFile: File;
    headerAvailable: boolean;
    characterEncoding: string;
    columnMapping: { [name: string]: number };
}

export interface ImportResult {
    totalItems: number;
    importedItems: number;
    skippedItems: number;
    failedItems: any[];
}

export enum StatisticTimeframe {
    Today = 'Today',
    Yesterday = 'Yesterday',
    ThisMonth = 'ThisMonth',
    LastMonth = 'LastMonth',
    Last3Months = 'Last3Months',
    ThisYear = 'ThisYear',
    LastYear = 'LastYear',
    Custom = 'Custom'
}

export interface StatisticRequest {
    timeframe: StatisticTimeframe,
    from?: Date,
    until?: Date,
    itemUuid?: string,
    modelUuid?: string,
    costCenterUuid?: string,
}

export interface StatisticDetails {
    dateCounters: { [date: string]: number },
    totalRentals: number,
    totalCost: number,
    highestCostRental: Rental,
    lowestCostRental: Rental,
    longestRental: Rental,
    shortestRental: Rental
}

export interface ImportUserRequest {
    email: string,
    firstName?: string,
    lastName?: string,
    costCenterName?: string,
    costCenterForeignId?: string,
	nfcToken?: string,
}

export interface ImportUserResult {
    email: string,
    ok?: boolean,
    errorCode?: number,
    user?: User;
}

export enum Availability {
    Available = "Available",
    Rented = "Rented"
}

export interface ItemAvailabilityResponse {
    availability: Availability;
    rentalUuid?: string;
}
