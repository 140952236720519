import {Api} from "../Api";
import {
	CalculationCase, Category,
	CategoryTree,
	ImportRequest,
	ImportResult,
	Item,
	ItemAvailabilityResponse,
	ItemDetails,
	ItemSetStateRequest,
	Model,
	RentalState, StoredItem, TaxedAmount,
	Ticket
} from "./types";
import {createQueryString, Page, Paging, PlainDateTime, SpringPageable} from "@variocube/app-ui";
import {makeCsvImportFormData} from "./form";
import { DateTime } from "luxon";
import {api, apiV2} from "./api";

export interface RentalFilterData {
	rentalState?: RentalState,
	needle?: string;
	from?:PlainDateTime | null;
	until?:PlainDateTime | null;
    availableFrom?:PlainDateTime | null;
	availableUntil?:PlainDateTime | null;
    categoryUuid?: string| null;
    category?: CategoryTree | undefined;
    modelUuid?: string| null;
    model?: Model | undefined;
}

export function searchItems(tenantId: string, needle: string, availableFrom?:DateTime, availableUntil?:DateTime,  modelUuid?: string| null) {
    var query = `tenants/${tenantId}/items?unpaged=1&needle=${needle}`;
    if (availableFrom) {
        query += "&availableFrom=" + availableFrom.toJSDate().toISOString() ;
    }
    if (availableUntil) {
        query += "&availableUntil=" + availableUntil.toJSDate().toISOString();
    }
    if (modelUuid) {
        query += "&modelUuid=" + modelUuid;
    }
    return Api.GET<Page<Item>>(query, { 'Accept': 'application/vnd.items.paged+json' });
}

export function listItems(tenantId: string, paging?: Paging, needle?: string) {
    var query = `tenants/${tenantId}/items${paging ? paging.toQueryString() : '?unpaged=1'}${needle ? '&needle=' + needle : ''}`;
    return Api.GET<Page<Item>>(query, { 'Accept': 'application/vnd.items.paged+json' });
}

export function getItem(tenantId: string, uuid: string) {
    return Api.GET<Item>(`tenants/${tenantId}/items/${uuid}`);
}

export function createItem(tenantId: string, details: ItemDetails) {
    return Api.POST<Item>(`tenants/${tenantId}/items`, details);
}

export function updateItem(tenantId: string, uuid: string, details: ItemDetails) {
    return Api.PUT<Item>(`tenants/${tenantId}/items/${uuid}`, details);
}

export function deleteItem(tenantId: string, uuid: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/items/${uuid}`);
}

export function setItemState(tenantId: string, uuid: string, request: ItemSetStateRequest) {
    return Api.PUT<Item>(`tenants/${tenantId}/items/${uuid}/state`, request);
}

export function listItemsTickets(tenantId: string, uuid: string) {
    return Api.GET<Ticket[]>(`tenants/${tenantId}/items/${uuid}/tickets`);
}

export function itemAvailability(tenantId: string, uuid: string) {
    return Api.GET<ItemAvailabilityResponse>(`tenants/${tenantId}/items/${uuid}/availability`);
}

export function itemGenerateCodes(tenantId: string, uuid: string) {
    return Api.POST<Item>(`tenants/${tenantId}/items/${uuid}/codes`, {});
}

export function itemRegenerateAllItemCodes(tenantId: string) {
    return Api.POST<void>(`tenants/${tenantId}/items/regenerate-all-codes`, {});
}

export function itemClearCodes(tenantId: string, uuid: string) {
    return Api.DELETE<Item>(`tenants/${tenantId}/items/${uuid}/codes`);
}

export async function importItems(tenantId: string, importRequest: ImportRequest) {
    return Api.POST_FORM_DATA<ImportResult>(`tenants/${tenantId}/items/import`, makeCsvImportFormData(importRequest));
}

export type ItemFilterTimeframeType = 'All'|'Available'|'Rented';

export interface ItemListFilter {
	needle?: string;
	categoryUuid?: string;
	modelUuid?: string;
	timeframeType?: ItemFilterTimeframeType;
	from?: string;
	until?: string;
	enabled?: boolean;
}

export interface V2Item {
	uuid: string;
	foreignId?: string;
	name: string;
	description?: string;
	centerId: string;
	created: string;
	updated: string;
	lastRented?: string;
	maxRentPeriod?: number;
	amount?: TaxedAmount;
	calculationCase?: CalculationCase;
	disabled: boolean;
	needInspection: boolean;
	adminCode?: string;
	openCode?: string;
	accessCodeGenerated?: string;
	images: string[];
	stored?: StoredItem;
	modelUuid?: string;
	modelName?: string;
	categoryUuid?: string;
	categoryName?: string;
}

type ItemOption = Pick<V2Item, 'uuid'|'name'>;

export const itemApi = {
	list(centerId: string, pageable: SpringPageable, filter?: ItemListFilter) {
		return apiV2.get<Page<Item>>(`/tenants/${centerId}/items?${createQueryString(pageable, filter ?? {})}`, {
			headers: {
				'Accept': 'application/vnd.items.paged+json'
			}
		})
	},
	listOptions(centerId: string) {
		return apiV2.get<ItemOption[]>(`/tenants/${centerId}/items/options`)
	},
	async regenerateAllItemCodes(centerId: string) {
		await apiV2.patch(`/tenants/${centerId}/items/regenerate-all-codes`)
	}
}
